<template>
  <div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-60 md-size-50">
      <md-field class="filter-input">
        <label>Filter</label>
        <md-input @keyup="() => getSlots(1)" v-model="filter"></md-input>
      </md-field>
    </div>
    <md-table v-if="data.data" v-model="data.data">
      <md-table-row
        class="pointer"
        @click="goToProfile(item.id)"
        slot="md-table-row"
        slot-scope="{ item }"
      >
        <md-table-cell md-label="Alias" v-if="showIcon">
          <v-icon color="primary" size="20" v-if="item.icon">
            {{ item.icon.layoutConfiguration.icon }}</v-icon
          >
          {{ item.alias }}</md-table-cell
        >
        <md-table-cell md-label="Alias" v-if="!showIcon">
          {{ item.alias }}</md-table-cell
        >
        <md-table-cell
          md-label="Parameter name"
          v-if="getOrientation === 90 || getOrientation === -90"
          >{{ item.parameterName }} {{ item.worker_last_name }}</md-table-cell
        >
        <md-table-cell md-label="Timestamp">{{ item.timestamp }}</md-table-cell>
      </md-table-row>
    </md-table>
    <pagination :data="data" @pagination-change-page="getSlots"></pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "slots-crud-mobile",
  data() {
    return {
      data: {},
      filter: "",
      deviceDisplayers: [],
      showIcon: false,
      showLastSeen: false,
    };
  },
  computed: {
    ...mapGetters("screen", ["getOrientation"]),
  },
  methods: {
    goToProfile(slot) {
      this.$router.push({
        name: "slotDetails",
        params: { device: this.$route.params.device, slot },
      });
    },
    getSlots(page = 1) {
      this.$store
        .dispatch("slot/getSlots", {
          device: this.$route.params.device,
          page: page,
          filter: this.filter,
        })
        .then((data) => {
          this.data = data;
          // this.setLastSeen();
        })
        .then(() => {
          this.getDeviceDisplayers();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDeviceDisplayers() {
      this.$store
        .dispatch("displayers/getDeviceDisplayers", this.$route.params.device)
        .then((data) => {
          this.deviceDisplayers = data;
        })
        .then(() => {
          this.setIcono();
        })
        .catch((err) => console.error(err));
    },
    setIcono() {
      this.data.data.forEach((slot) => {
        slot.icon = this.deviceDisplayers.find(
          (displayer) =>
            slot.id == displayer.slots[0].id &&
            displayer.layoutConfiguration.icon
        );
      });
      setTimeout(() => {
        this.showIcon = true;
      }, 100);
    },
    getSlotMeasurement(slot) {
      this.$store
        .dispatch("monitoring/getSlotMeasurement", slot.id)
        .then((measurement) => {
          slot.lastSeen = measurement.measurement.timestamp;
        });
    },
    setLastSeen() {
      this.data.data.forEach((slot) => {
        this.getSlotMeasurement(slot);
      });
    },
  },
  mounted() {
    this.getSlots();
  },
};
</script>